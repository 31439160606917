import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import './styles/root.scss'
import loadUsersFromStorage from 'utility/loadUsersFromStorage'
import { useAppDispatch } from 'store/store'
import { setLoading, setUsers } from 'store/reducers/usersReducer'
import Search from 'pages/Search'
import Footer from 'components/Footer'

const RootComponent: React.FC = () => {
    const dispatch = useAppDispatch();



    useEffect(() => {

        dispatch(setLoading(true));
        loadUsersFromStorage().then((users) => {
            dispatch(setUsers(users));
        })
        .catch(() => {

        })
        .finally(() => {
            dispatch(setLoading(false));
        })
    }, [])


    return (
        <Router>
            <div>
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                    <Route path={ROUTES.SEARCH_ROUTE} element={<Search />} />
                </Routes>
            </div>
            <Footer/>
        </Router>
    )
}

export default RootComponent