// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAaj_-rJhKo3lY8sxY1nLsmMI4uzH6uGT0",
    authDomain: "juntar-74b07.firebaseapp.com",
    projectId: "juntar-74b07",
    storageBucket: "juntar-74b07.appspot.com",
    messagingSenderId: "973689564450",
    appId: "1:973689564450:web:4b81388497b0f1e527c97b",
    measurementId: "G-7J2GPSRRH7"
};

let app: any = undefined;
// Initialize Firebase
const allFirebaseApps = getApps();
if(allFirebaseApps.length === 0)
{
    app = initializeApp(firebaseConfig);
}
else
{
    app = allFirebaseApps[0];
}

export function firestoreInstance() {
    return getFirestore(app);
}

export function storageInstance() {
    return getStorage(app);
}