import React, { useEffect, useRef, useState } from 'react'
import Loading from 'components/Loading'
import { useAppDispatch, useAppSelector } from 'store/store';
import UserCard from 'components/UserCard';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import SearchPanel from 'components/SearchPanel';
import IUser from 'models/IUser';
import _ from 'lodash';
import useOnScreen from 'hooks/useOnScreen';
import Header from 'components/Header';
import UserDetails from 'components/UserDetails';
import { setUserSelected } from 'store/reducers/usersReducer';
import "./Search.scss"
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const Search: React.FC = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const loading = useAppSelector(state => state.users.loading);
    const users = useAppSelector(state => state.users.users);
    const userSelected = useAppSelector(state => state.users.userSelected);
    const search = useAppSelector(state => state.users.search);
    const [usersFiltered, setUsersFiltered] = useState<IUser[]>();
    const [usersShown, setUsersShown] = useState<IUser[]>([]);

    const elementRef = useRef<HTMLDivElement>(null);
    const isOnScreen = useOnScreen(elementRef);
    
    const [pagelength, setPagelength] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(currentPage+1);
      }, [isOnScreen]);
      
    useEffect(() => {
        if(usersFiltered)
        {
            //const userOrdered = usersFiltered.filter(m => m.PHOTO != "2023.X");
            //usersFiltered.filter(m => m.PHOTO == "2023.X").forEach(m => userOrdered.push(m))

            setUsersShown(_.take(usersFiltered, currentPage * pagelength));
        }
    
    }, [usersFiltered, currentPage])


    useEffect(() => {
        let usersToFilter = users
        let noFilter = true;

        if(search.secteur)
        {
            usersToFilter = usersToFilter.filter(m => _.trim(m.SECTEUR) == search.secteur);
            noFilter = false;
        }

        if(search.ecole)
        {
            usersToFilter = usersToFilter.filter(m => _.trim(m.FORMATION).toLowerCase().includes(search.ecole.toLowerCase()));
            noFilter = false;
        }

        if(search.fonction)
        {
            usersToFilter = usersToFilter.filter(m => _.trim(m.FONCTION) == search.fonction);
            noFilter = false;
        }

        if(search.term)
        {
            usersToFilter = usersToFilter.filter(m =>   (m.PRENOM.toLowerCase() + " " + m.NOM.toLowerCase()).includes(search.term) ||
                                                        m.CLTS.toLowerCase().includes(search.term));
            noFilter = false;
        }


        // si aucun filtre selectionné, ne pas afficher les secteur "top adresse"
        if(noFilter)
        {
            usersToFilter = usersToFilter.filter(m => _.lowerCase(_.trim(m.SECTEUR)) != "top adresses");
        }

        setUsersFiltered(usersToFilter);
      }, [search, users]);


      const navigateHomePage = () => {
        navigate("/")
    }

      
      const currentSelectedIndex = userSelected ? usersFiltered?.findIndex(m => _.isEqual(m, userSelected)) : undefined;
      let nextFunc;
      let previousFunc;

      if(currentSelectedIndex != undefined && usersFiltered){

        if(currentSelectedIndex < usersFiltered.length - 1){
            nextFunc = () => {
                const nextUser = usersFiltered[currentSelectedIndex + 1];
                dispatch(setUserSelected(nextUser))
            }
        }
        
        if(currentSelectedIndex > 0)
        {
            previousFunc = () => {
                const previousUser = usersFiltered[currentSelectedIndex -1 ];
                dispatch(setUserSelected(previousUser))
            }
        }
      }

    return (
        <Container id="search" style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>


            <div id="header-section" className={'pt-2'}>
                <div className='text-center'><img id="app-logo" onClick={navigateHomePage} src="/images/logo_search.jpg"/></div>
            </div>
            <SearchPanel/>
            {loading && <Loading text={"Chargement"}/>}
            
            {/*usersFiltered && usersFiltered.length > 0 &&<div className='text-center'>{`Résultat${usersFiltered.length > 1 ? "s": ""}:  ${usersFiltered.length}`}</div>*/}
            <Row xs={1} md={2} lg={2} xl={3} xxl={4} className="g-4 p-2 w-100">
                {/*usersShown && usersShown.map((user, i) => {
                    return <Col key={i}><UserCard user={user} /></Col>
                })*/}

                {users && users.map((user, i) => {
                    const enable = _.some(usersShown, {'_ID': user._ID});
                    if(!enable) return <UserCard user={user} enable={false} />
                    else return <Col key={i}><UserCard user={user} enable={true} /></Col>
                })}

            </Row>

            {usersFiltered && usersFiltered.length == 0 && <div className='text-center mb-2'>Aucun résultat</div>}
            {usersFiltered && usersFiltered.length > usersShown.length  && <Loading text={"Chargement"}/>}
            <div className='mb-3' ref={elementRef}></div>
            
            {userSelected && <UserDetails user={userSelected} next={nextFunc} previous={previousFunc}/>}
           
        </Container>
    )
}

export default Search