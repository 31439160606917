import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import "./HomePage.scss"
import { useNavigate } from 'react-router-dom';


const HomePage: React.FC = () => {
    
    const navigate = useNavigate();

    const navigateSearchPage = () => {
        navigate("/search")
    }

    return (
        <div id="homepage" style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <div id="header-section" className={'pt-2'}>
                <div className='text-center'><img onClick={navigateSearchPage} id="app-logo" src="/images/Logo_start.jpg"/></div>
            </div>

            <div id="introduction">
                <div>{"Nous sommes particulièrement fiers de cette 3ème édition car elle prolonge le travail initié il y a 5 ans."}</div>
                <div className='text-center'><Button className='btn-orange w-50 mb-3 mt-3' onClick={navigateSearchPage}>{"Découvrir les résultats"}</Button></div>
                <div>{"Depuis notre lancement nous n'avons eu de cesse d'identifier, de suivre et de sélectionner les profils lusophones ayant un parcours d'exception sur le marché Français."}</div>
                <div>{"Si au départ nous ne pensions pouvoir mettre en avant que quelques profils, très vite, nous nous sommes rendus compte que le résultat de nos recherches dépassait très largement nos attentes."}</div>
                <div>{"En une, voire deux générations, les lusophones ont fait mentir les statistiques de la reproduction sociale."}</div>
                <div>{"Ils se sont accaparés de positions exceptionnelles tant au sein de structures existantes qu’au sein d’entreprises qu’ils ont eux-mêmes montés."}</div>
                <div>{"A nos yeux, ces trajectoires de formidables encouragements pour chacun d’entre nous."}</div>
                <div>
                    <div>{"Ils signifient :"}</div>
                    <ol>
                        <li>{"Il n'y a pas de fatalité. Nos rêves peuvent être accessibles si nous nous en donnons les moyens."}</li>
                        <li>{"Le modèle Français demeure l'un des plus généreux au monde : 27% des profils qui ont retenu notre attention ont suivi un parcours universitaire."}</li>
                        <li>{"L'immigration est une chance pour le pays d'accueil. La valeur ajoutée développée/pilotée par ces 500 profils dépassent très largement le milliard d'€ pour la France."}</li>
                    </ol>
                </div>
                <div>{"J'espère que ces parcours vous inspireront autant que nous."}</div>
                <div>{"Bravo à tous les sélectionnés de cette 3ème édition."}</div>
                <div>{"Bonne découverte."}</div>
                <div className='mt-3'><i>{"Georges Dos Santos"}</i></div>
                <div className='text-center mt-3 mb-4'><Button className='btn-orange w-50 mb-3 mt-3' onClick={navigateSearchPage}>{"Découvrir les résultats"}</Button></div>
                
            </div>

            <div>
                
            </div>
        </div>
    )
}

export default HomePage
