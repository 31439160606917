import { createAction, createReducer } from "@reduxjs/toolkit";
import ISearch from "models/ISearch";
import IUser from "models/IUser";

export interface IUsersStore {
    loading: boolean;
    users:IUser[];
    search:ISearch;
    userSelected: IUser | undefined;
}  
  
const initialState: IUsersStore = {
    loading: false,
    users: [],
    userSelected: undefined,
    search: {
        term: '',
        secteur: '',
        ecole: ''
    } as ISearch
}
  
export const setUsers = createAction<IUser[]>('usersReducer/setUsers')
export const setUserSelected = createAction<IUser | undefined>('usersReducer/setUserSelected')
export const setLoading = createAction<boolean>('usersReducer/setLoading')
export const setSearch = createAction<ISearch>('usersReducer/setSearch')

const usersReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(setUsers, (state, action) => { state.users = action.payload; })
        .addCase(setUserSelected, (state, action) => { state.userSelected = action.payload; })
        .addCase(setLoading, (state, action) => { state.loading = action.payload; })
        .addCase(setSearch, (state, action) => { state.search = action.payload; })
)

export default usersReducer;