import React, { useEffect, useState } from 'react';
import "./SearchPanel.scss"
import { useAppDispatch, useAppSelector } from 'store/store';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import { setSearch } from 'store/reducers/usersReducer';
import Select from 'react-select'
import Creatable, { useCreatable } from 'react-select/creatable';

const SearchPanel: React.FC = () => {

    const dispatch = useAppDispatch();
    const users = useAppSelector(state => state.users.users);
    const search = useAppSelector(state => state.users.search);

    const [secteurs, setSecteurs] = useState<{value: string, label: string}[]>([]);
    const [ecoles, setEcoles] = useState<{value: string, label: string}[]>([]);
    const [fonctions, setFonctions] = useState<{value: string, label: string}[]>([]);

    useEffect(() => {
        if(users)
        {
            setSecteurs(_.uniq(users.filter(m => !!m.SECTEUR).map(m => _.trim(m.SECTEUR))).sort().map(m => {return { value: m, label: m }}))
            setEcoles(_.uniq(users.filter(m => !!m.FORMATION).map(m => _.trim(m.FORMATION))).sort().map(m => {return { value: m, label: m }}))
            setFonctions (_.uniq(users.filter(m => !!m.FONCTION).map(m => _.trim(m.FONCTION))).sort().map(m => {return { value: m, label: m }}))
        }
    }, [users])

    return (
        <div id="search-component" className={'mt-2 mb-4'}>
           <Row>
                <Col xs="6" sm="6" md="3" className='mb-1'>
                    <Form.Control type="text" placeholder="Nom ou entreprise ..." onChange={(e) => dispatch(setSearch({...search, term: e.target.value.toLowerCase()}))}/>
                </Col>
                <Col xs="6" sm="6" md="3" className='mb-1'>
                    <Select options={fonctions} isClearable placeholder="Fonction ..." value={search.fonction ? {value: search.fonction, label: search.fonction} : undefined} onChange={(val) => dispatch(setSearch({...search, fonction: val?val.value : ""}))}/>
                </Col>
                <Col xs="6" sm="6" md="3" className='mb-1'>
                    <Select options={secteurs} isClearable placeholder="Secteur ..." value={search.secteur ? {value: search.fonction, label: search.secteur} : undefined} onChange={(val) => dispatch(setSearch({...search, secteur: val?val.value : ""}))}/>
                </Col>
                <Col xs="6" sm="6" md="3" className='mb-1'>
                    <Creatable options={ecoles} isClearable placeholder="Ecole ..." createOptionPosition="first"  formatCreateLabel={(inputVal) => inputVal}  value={search.ecole ?{value: search.ecole, label: search.ecole} : undefined} onChange={(val) => dispatch(setSearch({...search, ecole: val?val.value : ""}))}/>
                </Col>
           </Row>
        </div>
    )
}

export default SearchPanel;