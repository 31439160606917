import React, { useEffect, useState } from 'react'
import IUser from "models/IUser"
import { Card, Col } from 'react-bootstrap'
import { storageInstance } from 'FirebaseConfig'
import { getDownloadURL, ref } from 'firebase/storage'
import "./UserCard.scss";
import { setUserSelected } from 'store/reducers/usersReducer'
import { useAppDispatch } from 'store/store'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from 'lodash'

interface OwnProps {
    user: IUser;
    enable: boolean
 }
const UserCard: React.FC<OwnProps> = ({user, enable}) => {

    const dispatch = useAppDispatch();
    
    const [pictureUrl, setPictureUrl] = useState('/images/no-picture.png');
    const [currentUserId, setCurrentUserId] = useState(user._ID);


    useEffect(() => {

        setPictureUrl('/images/no-picture.png')
        setCurrentUserId(user._ID)
    }, [user])

   

    useEffect(() => {

        setPictureUrl('/images/no-picture.png')
        if(user && user.PHOTO)
        {
            const storage = storageInstance();
            getDownloadURL(ref(storage, `pictures/${user.PHOTO}.jpg`))
                .then((url) => {
                    //setPictureUrl(url);

                    if(user._ID == currentUserId)
                        setPictureUrl(url);

                })
                .catch(() => setPictureUrl('/images/no-picture.png'))
        }

        
    }, [currentUserId])


    const onClickUser = () => {
        dispatch(setUserSelected(user))
    }

    const fullName = _.trim(user.PRENOM + ' ' + user.NOM);

    if(!enable) return <></>;

    return <div className='user-card' onClick={onClickUser}>
        <div className='picture'>
            <LazyLoadImage
                alt={user.PRENOM + ' ' + user.NOM}
                
                src={pictureUrl}/>
        </div>
        <div className='details row'>
            {fullName && <div className='fullname text-truncate col-12' title={fullName}>{user.PRENOM + ' ' + user.NOM}</div>}
            {user.TITRE && <div className='title text-truncate col-12' title={user.TITRE}><i>{user.TITRE}</i></div>}
            {user.CLTS && <div className='company text-truncate col-12' title={user.CLTS}>{user.CLTS}</div>}
        </div>
    </div>
}

export default UserCard