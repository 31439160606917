import React, { useEffect, useState } from 'react';
import "./Footer.scss"
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';


const Footer: React.FC = () => {

    const [showMentionLegales, setShowMentionLegales] = useState(false);
    const navigate = useNavigate();
    
    const navigateSearchPage = () => {
        navigate("/search")
    }

    return (
        <div id="footer">
            <Container>
                <Row >
                    <Col className='links'>
                        <div><a href='javascript:void(0)' onClick={() => setShowMentionLegales(true)}>Mentions légales</a></div>
                        <div><a href='https://www.linkedin.com/in/georgesdossantos/' target='_blank' rel="noreferrer">Contactez-nous</a></div>
                    </Col>
                    <Col className='pt-2 text-center links'>
                            <a className='mt-2' href="https://www.linkedin.com/company/juntarclub/about/" target='_blank' rel="noreferrer">
                                {/*<img id="logo-linkedin" srcSet="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/640px-LinkedIn_logo_initials.png" />*/}
                                Suivez-nous
                            </a>
                        </Col>
                    <Col>
                        <div className='float-end'>
                            <a href='https://web.findnorder.com/' target='_blank' rel="noreferrer">
                                <img id="logo-fno" srcSet="/images/logo_fno.jpg" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={showMentionLegales}
                id="user-details-panel"
                size="lg"
                centered
                onHide={() => setShowMentionLegales(false)}>
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='color-orange text-uppercase'>
                    Mentions légales
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Le site Juntar500.com est édité par la SAS 76box, au capital de 3000 euros, immatriculé au RCS de Créteil sous le numéro : B 839 014 784</div>
                    <div>Siège social : 9 rue Fays 94160 Saint mande.</div>
                    <div>Son directeur de la publication est Monsieur Georges Dos Santos</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-dark w-100' onClick={() => setShowMentionLegales(false)}>Retour</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

   
}

export default Footer



