import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SpinnerDotted,  } from 'spinners-react';
import "./Loading.scss"


interface OwnProps {
    text?: string;
    fullScreen? : boolean;
    inline?: boolean;
    size?: number;
 }
const Loading: React.FC<OwnProps> = ({text = undefined, fullScreen = false, inline = false, size = undefined}) => {
    useEffect(() => {
        
    }, [])

    if(fullScreen)
    {
        return (
            <Row className={'loading-component fullscreen align-items-center'}>
                <Col>
                    <SpinnerDotted />
                    <div style={{ marginTop:"20px"}}>
                        {text}
                    </div>
                </Col>
            </Row>
        )
    }
    else if(inline)
    {
        return <SpinnerDotted size={size}/>;
    }

    return (
        <div className={'loading-component text-center mt-2'}>
            <SpinnerDotted color='#DE7D26'/>
           {text && <div style={{ marginTop:"20px"}}>
                {text}
            </div>}
        </div>
    )



   
}

export default Loading
