import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Image } from 'react-bootstrap';
import { SpinnerDotted,  } from 'spinners-react';
import "./UserDetails.scss"
import IUser from 'models/IUser';
import { setUserSelected } from 'store/reducers/usersReducer';
import { useAppDispatch } from 'store/store';
import { storageInstance } from 'FirebaseConfig';
import { getDownloadURL, ref } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';


interface OwnProps {
    user: IUser;
    next?: () => void;
    previous?: () => void;
 }
const UserDetails: React.FC<OwnProps> = ({user, next, previous}) => {
    
    const dispatch = useAppDispatch();
    const [pictureUrl, setPictureUrl] = useState('/images/no-picture.png');
    const [showPresentation, setShowPresentation] = useState(false)

    useEffect(() => {

        if(user)
        {
            setShowPresentation(false);
            setPictureUrl('/images/no-picture.png');
            if(user.PHOTO)
            {
                const storage = storageInstance();
                getDownloadURL(ref(storage, `pictures/${user.PHOTO}.jpg`))
                    .then((url) => setPictureUrl(url))
                    .catch(() => setPictureUrl('/images/no-picture.png'))
            }
        }
    }, [user._ID])

 
    const onClose = () => {
        dispatch(setUserSelected(undefined))
    }

    return (<Modal
        show
        id="user-details-panel"
        size="lg"
        centered
        onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='color-orange text-uppercase'>
            {user.PRENOM + " " + user.NOM}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                {previous && <Col xs={1} className='arrow' onClick={previous}><div><FontAwesomeIcon icon={["fas", "chevron-left"]} size="lg"/></div></Col>}
                <Col>

                    <Row>
                        <Col sm="4">
                            <div className='picture mb-2'><Image src={pictureUrl} rounded /></div>
                        </Col>
                        <Col sm="8">
                            <div className='sector'>{user.SECTEUR}</div>
                            <div className='fullname mt-1 fs-4'>{user.TITRE}</div>
                            {user.CLTS && <div className='company mt-1'><FontAwesomeIcon icon={["fas", "building"]} /> {user.CLTS}</div>}
                            {user.FORMATION && <div className='company mt-1'><FontAwesomeIcon icon={["fas", "graduation-cap"]} /> {user.FORMATION}</div>}

                            <div className='social-media mt-1'>
                                {user.LINKEDIN && <div ><a href={user.LINKEDIN} target='_blank' rel="noreferrer"><img height={30} src="./images/linkedin.png"/></a></div>}
                            </div>

                            {user.DESCRIPTION && !showPresentation && <div className='mt-3 text-center show-more'><a href='javascript:void(0)' onClick={() => setShowPresentation(true)}>Voir le portrait de {user.PRENOM }</a></div>}


                            {user.DESCRIPTION && showPresentation && <div className='description' dangerouslySetInnerHTML={{__html: user.DESCRIPTION}}></div>}
                        </Col>
                    </Row>
                    




                </Col>
                {next && <Col xs={1} className='arrow' onClick={next}><div><FontAwesomeIcon icon={["fas", "chevron-right"]} size="lg"/></div></Col>}
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-dark w-100' onClick={onClose}>Retour</Button>
        </Modal.Footer>
      </Modal>)



   
}

export default UserDetails
