import { getDownloadURL, ref } from "firebase/storage";
import {storageInstance } from "../FirebaseConfig"
import axios from "axios";
import IUser from "models/IUser";
import _ from "lodash";

/**
 * Load users from storage
 */
export default async function loadUsersFromStorage(){

    const storage = storageInstance();
    const url = await getDownloadURL(ref(storage, 'users.csv'))
    const fileContent = (await axios.get(url)).data

    const dataRows: string[] = fileContent.trim().split('\n');
    const dataArray: IUser[] = dataRows.slice(1).map((row: string, index) => {
        const allCols = row.split(';');
        const description = allCols[11];

        const prenom = allCols[3] ? _.trim(allCols[3]) : "";
        const nom = allCols[4] ? _.trim(allCols[4]) : "";

        return {
            _ID: index + 1,
            CLTS: allCols[0],
            SECTEUR: allCols[1],
            CIV: allCols[2],
            PRENOM: prenom,
            NOM: nom,
            LINKEDIN: allCols[5],
            FONCTION: allCols[6],
            TITRE: allCols[7],
            PHOTO: allCols[8],
            REGION: allCols[9],
            FORMATION: allCols[10],
            DESCRIPTION: description == "\r" ? "": description,
        } as IUser;
    });

    console.log("dataArray", dataArray);
    // Mettre les profiles sans photo à la fin
    const userOrdered = dataArray.filter(m => m.PHOTO != "2023.X");
    dataArray.filter(m => m.PHOTO == "2023.X").forEach(m => userOrdered.push(m))


    // mettre à la fin Pereira
    const pereira = _.remove(userOrdered, (item) => item.PRENOM == "Armando" && item.NOM == "Pereira");
    console.log(pereira);
    if(pereira.length > 0)
    {
        userOrdered.push(pereira[0])
    }



    return userOrdered;

}